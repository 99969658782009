import type { CrmNavigationItem } from '~/types/Navigation';

const navigationItems: CrmNavigationItem[] = [
    {
        label: 'Dashboard',
        to: {
            path: '/dashboard',
        },
    },
    {
        label: 'Relaties',
        to: {
            path: '/relations',
        },
    },
    {
        label: 'Inschrijvingen',
        to: {
            path: '/signups',
        },
    },
    {
        label: 'Bedrijven',
        to: {
            path: '/companies',
        },
    },
    {
        label: 'Certificaten',
        to: {
            path: '/certificates',
        },
    },
    {
        label: 'Registraties',
        to: {
            path: '/registrations',
        },
    },
    {
        label: 'Cursussen',
        to: {
            path: '/courses',
        },
    },
    {
        label: 'Niveaumetingen',
        to: {
            path: '/level-of-measurements/no-relation',
        },
    },
    {
        label: 'Facturen',
        to: {
            path: '/invoices',
        },
    },
    {
        label: 'Rapporten',
        to: {
            path: '/reports',
        },
    },
    {
        label: 'Notificaties',
        to: {
            path: '/extranet-notifications',
        },
    },
    {
        label: 'Instellingen',
        to: {
            path: '/users',
        },
    },
    {
        href: 'https://www.vastgoedcert.nl/',
        label: 'www.vastgoedcert.nl',
        target: '_blank',
    },
];

export default navigationItems;
